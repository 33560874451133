import { Link, useParams } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import css from './SoloEdit.module.css'
import { getRecruitment, updateRecruitment } from "../api";

export function RecruitmentPage() {

    let [title,setTitle] = useState('')
    let [description,setDescription] = useState('')
    let [image,setImage] = useState('')
    let {id} = useParams()

    useEffect(() => {
        getRecruitment(id).then(res => {
            setTitle(res.title)
            setDescription(res.description)
            setImage(res.image)
        })
    }, [])
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        // Initialize FileReader
        const reader = new FileReader();

        // Set up FileReader onload callback
        reader.onload = () => {
            // Read the file as a Data URL (Base64 string representation)
            const base64Result = reader.result;
            setImage(base64Result)
        };

        reader.readAsDataURL(file);
    };

    function handleUpdate() {
        updateRecruitment(id, {
            title,
            description,
            image
        }).then(() => {
            alert('Success!')
        })
    }

    return (
        <MainLayout title={'Recruitment Page'}>
            <div className={css.header}>
                <Link to={'/recruitments'}>Back</Link>
                <h1>Edit recruitment</h1>
            </div>
            <div className={css.inputs}>
            <div className={css.inputBlock}>
                    <h1>Title</h1>
                    <input value={title} onChange={(e) => {
                        setTitle(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Description</h1>
                    <textarea value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </div>

                <div className={css.inputBlock}>
                    <h1>Image</h1>
                    <input onChange={(e) => {
                        handleFileChange(e)
                    }} type="file" />
                    <img src={image} alt="" />
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {handleUpdate()}}>Edit</button>
            </div>
        </MainLayout>
    )
}