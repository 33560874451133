import { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import css from './SettingsPage.module.css'
import { getAllSettings, sendCommands, updateSettings } from "../api";

export default function SettingsPage(){
    let [prefix, setPrefix] = useState('')
    let [bankChannel, setBankChannel] = useState('')
    let [bankConslusionChannel, setBankConslusionChannel] = useState('')
    let [soloEventChannel, setSoloEventChannel] = useState('')
    let [teamEventChannel, setTeamEventChannel] = useState('')
    let [teamChannel, setTeamChannel] = useState('')
    let [teamRecruitmentChannel, setTeamRecruitmentChannel] = useState('')
    let [citizenChannel, setCitizenChannel] = useState('')
    let [verificationChannel, setVerificationChannel] = useState('')
    let [hangarChannel, setHangarChannel] = useState('')
    let [corporationCheck, setCorporationCheck] = useState('')
    let [guildId, setGuildId] = useState('')

    useEffect(() => {
        getAllSettings().then(res => {
            setPrefix(res.motherPrefix)
            setBankChannel(res.bankChannel)
            setSoloEventChannel(res.soloEventsChannel)
            setTeamEventChannel(res.teamEventsChannel)
            setTeamChannel(res.teamChannel)
            setCitizenChannel(res.citizenChannel)
            setVerificationChannel(res.verificationChannel)
            setGuildId(res.guildId)
            setBankConslusionChannel(res.bankConclusionChannel)
            setTeamRecruitmentChannel(res.teamRecruitmentChannel)
            setHangarChannel(res.hangarChannel)
            setCorporationCheck(res.corporationCheck)
        })
    }, [])

    async function handleChange(type, key) {
        updateSettings({
            property: type,
            key
        })

        alert('Updated!')
    }

    function handleSend(type) {
        sendCommands(type)

        alert('Success!')
    }

    return (
        <MainLayout title={'Settings page'}>
            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Organization Prefix</h1>
                    <input type="text" onChange={(e) => {setPrefix(e.target.value)}} value={prefix}/>
                    <button onClick={() => {handleChange('motherPrefix', prefix)}}>Save</button>
                </div>
                <div className={css.inputBlock}>
                    <h1>Bank Channel</h1>
                    <input type="text" onChange={(e) => {setBankChannel(e.target.value)}} value={bankChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('bankChannel', bankChannel)}}>Save</button>
                        <button onClick={() => {handleSend('bankChannel')}}>Send commands</button>
                    </div>
                </div>
                <div className={css.inputBlock}>
                    <h1>Bank Conclusion Channel</h1>
                    <input type="text" onChange={(e) => {setBankConslusionChannel(e.target.value)}} value={bankConslusionChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('bankConclusionChannel', bankConslusionChannel)}}>Save</button>
                        {/* <button onClick={() => {handleSend('bankConclusionChannel')}}>Send commands</button> */}
                    </div>
                </div>
                <div className={css.inputBlock}>
                    <h1>Solo-events Channel</h1>
                    <input type="text" onChange={(e) => {setSoloEventChannel(e.target.value)}} value={soloEventChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('soloEventsChannel', soloEventChannel)}}>Save</button>
                        {/* <button onClick={() => {}}>Send commands</button> */}
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Team-events Channel</h1>
                    <input type="text" onChange={(e) => {setTeamEventChannel(e.target.value)}} value={teamEventChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('teamEventsChannel', teamEventChannel)}}>Save</button>
                        {/* <button onClick={() => {}}>Send commands</button> */}
                    </div>
                </div>


                <div className={css.inputBlock}>
                    <h1>Citizen Channel</h1>
                    <input type="text" onChange={(e) => {setCitizenChannel(e.target.value)}} value={citizenChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('citizenChannel', citizenChannel)}}>Save</button>
                        <button onClick={() => {handleSend('citizenChannel')}}>Send commands</button>
                    </div>
                </div>


                <div className={css.inputBlock}>
                    <h1>Team Channel</h1>
                    <input type="text" onChange={(e) => {setTeamChannel(e.target.value)}} value={teamChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('teamChannel', teamChannel)}}>Save</button>
                        <button onClick={() => {handleSend('teamChannel')}}>Send commands</button>
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Team Recruitment Channel</h1>
                    <input type="text" onChange={(e) => {setTeamRecruitmentChannel(e.target.value)}} value={teamRecruitmentChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('teamRecruitmentChannel', teamRecruitmentChannel)}}>Save</button>
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Verification Channel</h1>
                    <input type="text" onChange={(e) => {setVerificationChannel(e.target.value)}} value={verificationChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('verificationChannel', verificationChannel)}}>Save</button>
                        <button onClick={() => {handleSend('verificationChannel')}}>Send commands</button>
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Guild Id</h1>
                    <input type="text" onChange={(e) => {setGuildId(e.target.value)}} value={guildId}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('guildId', guildId)}}>Save</button>
                        {/* <button onClick={() => {}}>Send commands</button> */}
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Hangar Channel</h1>
                    <input type="text" onChange={(e) => {setHangarChannel(e.target.value)}} value={hangarChannel}/>
                    <div className={css.buttons}>
                        <button onClick={() => {handleChange('hangarChannel', hangarChannel)}}>Save</button>
                        <button onClick={() => {handleSend('hangarChannel')}}>Send commands</button>
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Corporation auto check</h1>
                    <div className={css.buttons}>
                        <button onClick={() => {
                            if(corporationCheck == "on") setCorporationCheck("off")
                            else if(corporationCheck == "off") setCorporationCheck("on")
                            else setCorporationCheck("on")
                        }}>status: {(corporationCheck == "off" || corporationCheck == undefined) ? 'Выключена' : 'Включена'}</button>
                        <button onClick={() => {handleChange('corporationCheck', corporationCheck)}}>Save</button>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}