// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoloEdit_header__HLGLJ a {
    border-radius: 0;
}

.SoloEdit_inputs__2Mubi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SoloEdit_inputBlock__Y9-oa {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.SoloEdit_inputBlock__Y9-oa img {
    width: 200px;
}

.SoloEdit_inputBlock__Y9-oa h1 {
    margin: 0;
}

.SoloEdit_inputBlock__Y9-oa input {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloEdit_inputBlock__Y9-oa textarea {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloEdit_buttons__Xwgcd {
    margin-top: 20px;
}

.SoloEdit_buttons__Xwgcd button {
    padding: 5px 25px;
    font-size: 20px;
    color: white;
    border: 1px solid rgb(18, 120, 18);
    background: rgb(18, 120, 18);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/SoloEdit.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kCAAkC;IAClC,4BAA4B;IAC5B,eAAe;AACnB","sourcesContent":[".header a {\r\n    border-radius: 0;\r\n}\r\n\r\n.inputs {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.inputBlock {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n}\r\n\r\n.inputBlock img {\r\n    width: 200px;\r\n}\r\n\r\n.inputBlock h1 {\r\n    margin: 0;\r\n}\r\n\r\n.inputBlock input {\r\n    padding: 5px 10px;\r\n    border: 1px solid white;\r\n    color: white;\r\n    background: none;\r\n    font-size: 17px;\r\n}\r\n\r\n.inputBlock textarea {\r\n    padding: 5px 10px;\r\n    border: 1px solid white;\r\n    color: white;\r\n    background: none;\r\n    font-size: 17px;\r\n}\r\n\r\n.buttons {\r\n    margin-top: 20px;\r\n}\r\n\r\n.buttons button {\r\n    padding: 5px 25px;\r\n    font-size: 20px;\r\n    color: white;\r\n    border: 1px solid rgb(18, 120, 18);\r\n    background: rgb(18, 120, 18);\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `SoloEdit_header__HLGLJ`,
	"inputs": `SoloEdit_inputs__2Mubi`,
	"inputBlock": `SoloEdit_inputBlock__Y9-oa`,
	"buttons": `SoloEdit_buttons__Xwgcd`
};
export default ___CSS_LOADER_EXPORT___;
