import { useEffect, useState } from "react";
import { clearTransferOperations, clearVaultItems, getDebtLogs, getTransferRequests, getVaultItemsOperations, updateTransferRequest } from "../api";
import MainLayout from "../layouts/MainLayout";
import css from './TransferPage.module.css'

export default function VaultItems(){

    let [transfers, setTransfers] = useState([])

    useEffect(() => {
        getVaultItemsOperations().then(res => {
            setTransfers(res)
        })
    }, [])

    function handleApprove(id, approved) {
        updateTransferRequest({
            id,
            approved
        })

        window.location.reload()
    }

    return (
        <MainLayout title={'Vault Items'}>
            <div className={css.sortBlock}>
                <button onClick={() => {clearVaultItems().then(() => {window.location.reload()})}}>Clear Logs</button>
            </div>
            <div className={css.transferList}>

            {transfers.map(operation => {
                        return ( <div className={css.transferRequest}>
                            <p>ID: {operation.id}</p>
                            <p>Main Category: {operation.mainCategory}</p>
                            <p>Location: {operation.location}</p>
                            <p>Modification Level: {operation.modificationLevel}</p>
                            <p>Extraction Type: {operation.extractionType}</p>
                            <p>Sub Category: {operation.subCategory}</p>
                            <p>Item Name: {operation.itemName}</p>
                            <p>Units Count: {operation.unitsCount}</p>
                            <p>Purchase Price: {operation.purchasePrice}</p>
                            <p>Selling Price: {operation.sellingPrice}</p>
                            <p>Final Price: {operation.finalPrice}</p>
                          </div>)
                })}
            </div>
        </MainLayout>
    )
}