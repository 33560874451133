import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './ExchangePage.module.css'
import { useEffect, useState } from "react";
import { getRate, updateRate } from "../api";


export function ExchangePage() {

    let [rate, setRate] = useState(0)

    useEffect(() => {
        getRate().then(res => {
            setRate(res.rate)
        })
    }, [])

    function updateRateHandler() {
        updateRate({rate: parseInt(rate)})

        alert('Success!')
    }

    return (
        <MainLayout title={'Exchange page'}>
            <div className={css.links}>
                <Link to={'/exchange'}>Debt Managment</Link>
                <Link to={'/exchange/roles'}>Role Managment</Link>
                <Link to={'/exchange/titles'}>Title Managment</Link>
                <Link to={'/exchange/items'}>Item Managment</Link>
            </div>

            <div className={css.body}>
                <h2>Курс баллов к валюте</h2>
                <div className={css.exchangeBox}>
                    <h1>1 балл = </h1>
                    <input value={rate} onChange={(e) => {setRate(e.target.value)}} type="text"/>
                    <h1>валюты</h1>
                </div>

                <button onClick={() => {updateRateHandler()}}>Save</button>
            </div>
        </MainLayout>
    )
}