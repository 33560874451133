// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankPage_header__YGEN3 a {
    border-radius: 0;
    width: 100px;
}

.BankPage_categories__WqE-b {
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.BankPage_header__YGEN3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.BankPage_header__YGEN3 h1 {
    font-size: 20px;
}

.BankPage_inputs__bIOEG {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.BankPage_inputBlock__\\+--Xb {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1;
}

.BankPage_inputBlock__\\+--Xb h1 {
    margin: 0;
}

.BankPage_inputBlock__\\+--Xb input {
    width: 400px;
    padding: 10px;
    color:  white;
    background: none;
    border: 1px solid white;
    border-radius: 10px;
}

.BankPage_inputBlock__\\+--Xb button {
    width: 200px;
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/BankPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB","sourcesContent":[".header a {\r\n    border-radius: 0;\r\n    width: 100px;\r\n}\r\n\r\n.categories {\r\n    gap: 10px;\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.header {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.header h1 {\r\n    font-size: 20px;\r\n}\r\n\r\n.inputs {\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin-top: 20px;\r\n}\r\n\r\n.inputBlock {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n    flex: 1;\r\n}\r\n\r\n.inputBlock h1 {\r\n    margin: 0;\r\n}\r\n\r\n.inputBlock input {\r\n    width: 400px;\r\n    padding: 10px;\r\n    color:  white;\r\n    background: none;\r\n    border: 1px solid white;\r\n    border-radius: 10px;\r\n}\r\n\r\n.inputBlock button {\r\n    width: 200px;\r\n    border: 1px solid white;\r\n    background: none;\r\n    color: white;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `BankPage_header__YGEN3`,
	"categories": `BankPage_categories__WqE-b`,
	"inputs": `BankPage_inputs__bIOEG`,
	"inputBlock": `BankPage_inputBlock__+--Xb`
};
export default ___CSS_LOADER_EXPORT___;
