import { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import css from './SoloNew.module.css'
import { deletePlayer, deleteShip, getPlayers, getShip, getShips, getShipsStats, parseShip, parseShips, updateReward, updateShip } from "../api";
import { Link, useParams } from "react-router-dom";

export default function SingleShipPage() {

    let [name, setName] = useState('')
    let [description, setDescription] = useState('none')
    let [characteristics, setCharacteristics] = useState([])
    let [meta, setMeta] = useState([])
    let [image, setImage] = useState('')
    let [price, setPrice] = useState('')
    let [status, setStatus] = useState('')
    let [newCharacteristic, setNewCharacteristic] = useState('')
    let [newMeta, setNewMeta] = useState('')
    let [extId, setExtId] = useState('')

    let {id} = useParams()

    function saveShip() {
        updateShip(id, {
            name,
            description,
            price,
            status,
            characteristics,
            meta
        })
    }

    function addCharacteristic() {
        if (newCharacteristic.trim()) {
            setCharacteristics([...characteristics, newCharacteristic.trim()])
            setNewCharacteristic('')
        }
    }

    function removeCharacteristic(index) {
        setCharacteristics(characteristics.filter((_, i) => i !== index))
    }

    function addMeta() {
        if (newMeta.trim()) {
            setMeta([...meta, newMeta.trim()])
            setNewMeta('')
        }
    }

    function removeMeta(index) {
        setMeta(meta.filter((_, i) => i !== index))
    }

    useEffect(() => {
        getShip(id).then(res => {
            setName(res.name)
            setDescription(res.description)
            setImage(res.image)
            setPrice(res.price)
            setStatus(res.status)
            setCharacteristics(res.characteristics || [])
            setMeta(res.meta || [])
            setExtId(res.externalId)
        })
    }, [])

    return (
        <MainLayout title={`Ship`}>
            <div className={css.header}>
                <Link to={'/ships'}>Back</Link>
                <h1>Edit Ship</h1>
                <button onClick={() => {parseShip(extId).then(() => {window.location.reload()})}}>Clear</button>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input onChange={(e) => {
                        setName(e.target.value)
                    }} value={name} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Description</h1>
                    <textarea value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </div>

                <div className={css.inputBlock}>
                    <h1>Image</h1>
                    <img src={image} alt="" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Status</h1>
                    <input value={status} onChange={(e) => {
                        setStatus(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Price</h1>
                    <input value={price} onChange={(e) => {
                        setPrice(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Characteristics</h1>
                    <div className={css.arrayInputs}>
                        {characteristics.map((char, index) => (
                            <div key={index} className={css.arrayItem}>
                                <span>{char}</span>
                                <button onClick={() => removeCharacteristic(index)}>×</button>
                            </div>
                        ))}
                        <div className={css.addNew}>
                            <input 
                                value={newCharacteristic}
                                onChange={(e) => setNewCharacteristic(e.target.value)}
                                placeholder="New characteristic"
                                onKeyPress={(e) => e.key === 'Enter' && addCharacteristic()}
                            />
                            <button onClick={addCharacteristic}>Add</button>
                        </div>
                    </div>
                </div>

                <div className={css.inputBlock}>
                    <h1>Meta</h1>
                    <div className={css.arrayInputs}>
                        {meta.map((item, index) => (
                            <div key={index} className={css.arrayItem}>
                                <span>{item}</span>
                                <button onClick={() => removeMeta(index)}>×</button>
                            </div>
                        ))}
                        <div className={css.addNew}>
                            <input 
                                value={newMeta}
                                onChange={(e) => setNewMeta(e.target.value)}
                                placeholder="New meta"
                                onKeyPress={(e) => e.key === 'Enter' && addMeta()}
                            />
                            <button onClick={addMeta}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveShip()}}>Save</button>
            </div>
        </MainLayout>
    )
}