import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './PlayerList.module.css'
import { useEffect, useState } from "react";
import { geRecruitmentsTeams } from "../api";


export function RecruitmentsPage() {

    let [teams, setTeams] = useState([])

    useEffect(() => {
        geRecruitmentsTeams().then((res) => {
            setTeams(res)
        })
    }, [])

    return (
        <MainLayout title={'Recruitments Page'}>
            <div className={css.players}>
                <div className={css.player}>
                    <h1>Team Name</h1>
                </div>
                {teams.map(team => (
                    <div className={css.playerManagment}>
                        <div className={css.player}>
                            <h1>{team.name}</h1>
                        </div>
                        <Link to={`/recruitment/${team.id}`}>Edit</Link>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}