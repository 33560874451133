import { Link } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'
import css from './BankPage.module.css'
import { useEffect, useState } from 'react'
import { getCapital, saveCapital } from '../api'


export default function BankPage() {
    let [capital, setCapital] = useState(0)

    useEffect(() => {
        getCapital().then(res => {
            setCapital(res.capital)
        })
    }, [])

    function handleSave() {
        saveCapital({
            capital,
            name: "main"
        })
        alert('Updated!')
    }

    return (
        <MainLayout title={'Bank'}>
            <div className={css.header}>
                <div className={css.categories}>
                    <Link to={'/bank/debt'}>Debts</Link>
                    <Link to={'/bank/transfers'}>Transfers</Link>
                </div>
                <h1>Bank Money: {capital}</h1>
            </div>
            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Bank Capital</h1>
                    <input type="text" onChange={(e) => {setCapital(e.target.value)}} value={capital}/>
                    <button onClick={() => {handleSave()}}>Save</button>
                </div>
            </div>
        </MainLayout>
    )
}