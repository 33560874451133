import MainLayout from "../layouts/MainLayout";
import { Link, Navigate } from "react-router-dom";
import css from './SoloNew.module.css'
import { useEffect, useState } from "react";
import {createSoloEvents, getRoles} from '../api'

export default function SoloNew() {
    let [name, setName] = useState('')
    let [description, setDescription] = useState('')
    let [link, setLink] = useState('')
    let [image, setImage] = useState('')
    let [date, setDate] = useState('')
    let [goal, setGoal] = useState('none')
    let [winPoints, setWinPoints] = useState(1)
    let [playableRoles, setPlayableRoles] = useState([])
    let [roles, setRoles] = useState([])

    useEffect(() => {
        getRoles().then(res => {
            setRoles(res)
        })
    }, [])

    function saveEvent() {

        if(!name) {
            alert('Enter name please')
            return
        }
        if(!description) {
            alert('Enter description please')
            return
        }
        if(!link) {
            alert('Enter link please')
            return
        }

        if(!date) {
            alert('Enter date please')
            return
        }

        if(goal == 'none') {
            alert('Select goal please')
            return
        }

        createSoloEvents({
            name,
            description,
            link,
            image,
            date,
            goal,
            winPoints: parseInt(winPoints),
            playableRoles
        }).then(() => {
            window.location.href ="/solo-events"
            // alert('success')
        })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        // Initialize FileReader
        const reader = new FileReader();

        // Set up FileReader onload callback
        reader.onload = () => {
            // Read the file as a Data URL (Base64 string representation)
            const base64Result = reader.result;
            setImage(base64Result)
        };

        reader.readAsDataURL(file);
    };

    function addNewPlayablerole() {
        const newArr = [...playableRoles, {
            RoleId: "none",
            amount: 1
        }];
        setPlayableRoles(newArr);
    }

    function changePlayableRole(e, index, property) {
        const newArr = playableRoles.map((r, i) => {
            if(i == index) {
                return {
                    ...r,
                    [property]: e.target.value
                }
            } else {
                return r
            }
        })

        setPlayableRoles(newArr)
    }

    return (
        <MainLayout title={'Solo Events New'}>
            <div className={css.header}>
                <Link to={'/solo-events'}>Back</Link>
                <h1>New event</h1>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input onChange={(e) => {
                        setName(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Description</h1>
                    <textarea  onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </div>

                <div className={css.inputBlock}>
                    <h1>Link</h1>
                    <input onChange={(e) => {
                        setLink(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Goal</h1>
                    <select onChange={(e) => {setGoal(e.target.value)}}>
                        <option value={'none'}>Нету</option>
                        <option value={'building'}>Стройка</option>
                        <option value={'explore'}>Исследование</option>
                        <option value={'rp'}>RP</option>
                        <option value={'earning'}>Доход</option>
                        <option value={'gather'}>Сбор</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Image</h1>
                    <input onChange={(e) => {
                        handleFileChange(e)
                    }} type="file" />
                    <img src={image} alt="" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Date</h1>
                    <input onChange={(e) => {
                        setDate(e.target.value)
                    }} type="datetime-local" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Solo Points</h1>
                    <input onChange={(e) => {
                        setWinPoints(e.target.value)
                    }} type="number" />
                </div>
            </div>
            <div className={css.roles}>
                <h1>Roles to play</h1>
                {playableRoles.map((r, index) => {
                    return <div className={css.role}>
                        <select onChange={(e) => {changePlayableRole(e, index, "RoleId")}}>
                            <option value="none">None</option>
                            {roles.map(role => {
                                return <option value={role.id}>{role.name}</option>
                            })}
                        </select>

                        <input type="text" onChange={(e) => {changePlayableRole(e, index, "amount")}} placeholder="Квота" />
                    </div>
                })}
                <button onClick={() => {addNewPlayablerole()}}>Add new</button>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveEvent()}}>Create</button>
            </div>
        </MainLayout>
    )
}