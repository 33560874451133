// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsPage_inputs__-v5uL {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.SettingsPage_inputBlock__hh97b {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1;
}

.SettingsPage_inputBlock__hh97b h1 {
    margin: 0;
}

.SettingsPage_inputBlock__hh97b input {
    width: 400px;
    padding: 10px;
    color:  white;
    background: none;
    border: 1px solid white;
    border-radius: 10px;
}

.SettingsPage_inputBlock__hh97b button {
    width: 200px;
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.SettingsPage_buttons__cL811 {
    display: flex;
    flex-direction: row;
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SettingsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".inputs {\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.inputBlock {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n    flex: 1;\r\n}\r\n\r\n.inputBlock h1 {\r\n    margin: 0;\r\n}\r\n\r\n.inputBlock input {\r\n    width: 400px;\r\n    padding: 10px;\r\n    color:  white;\r\n    background: none;\r\n    border: 1px solid white;\r\n    border-radius: 10px;\r\n}\r\n\r\n.inputBlock button {\r\n    width: 200px;\r\n    border: 1px solid white;\r\n    background: none;\r\n    color: white;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.buttons {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": `SettingsPage_inputs__-v5uL`,
	"inputBlock": `SettingsPage_inputBlock__hh97b`,
	"buttons": `SettingsPage_buttons__cL811`
};
export default ___CSS_LOADER_EXPORT___;
