// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferPage_transferList__f1CPg {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.TransferPage_transferRequest__GLwkP {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    justify-content: space-between;
}

.TransferPage_transferRequest__GLwkP .TransferPage_buttons__P1j6p {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.TransferPage_transferRequest__GLwkP p {
    width: 200px;
}

.TransferPage_transferRequest__GLwkP button {
    padding: 10px 15px;
    cursor: pointer;
}

#TransferPage_reject__sEvq4 {
    border: none;
    background: red;
    color: white;
    font-weight: 900;
}

#TransferPage_approve__7MX9K {
    border: none;
    background: green;
    color: white;
    font-weight: 900;
}

.TransferPage_sortBlock__mb\\+NV button {
    padding: 10px;
    border: 1px solid white;
    background: none;
    color: white;
    width: 150px;
    cursor: pointer;
}

.TransferPage_sortBlock__mb\\+NV {
    display: flex;
    flex-direction: row;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TransferPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".transferList {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    width: 100%;\r\n}\r\n\r\n.transferRequest {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    width: 100%;\r\n    margin: 0;\r\n    justify-content: space-between;\r\n}\r\n\r\n.transferRequest .buttons {\r\n    width: 200px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.transferRequest p {\r\n    width: 200px;\r\n}\r\n\r\n.transferRequest button {\r\n    padding: 10px 15px;\r\n    cursor: pointer;\r\n}\r\n\r\n#reject {\r\n    border: none;\r\n    background: red;\r\n    color: white;\r\n    font-weight: 900;\r\n}\r\n\r\n#approve {\r\n    border: none;\r\n    background: green;\r\n    color: white;\r\n    font-weight: 900;\r\n}\r\n\r\n.sortBlock button {\r\n    padding: 10px;\r\n    border: 1px solid white;\r\n    background: none;\r\n    color: white;\r\n    width: 150px;\r\n    cursor: pointer;\r\n}\r\n\r\n.sortBlock {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transferList": `TransferPage_transferList__f1CPg`,
	"transferRequest": `TransferPage_transferRequest__GLwkP`,
	"buttons": `TransferPage_buttons__P1j6p`,
	"reject": `TransferPage_reject__sEvq4`,
	"approve": `TransferPage_approve__7MX9K`,
	"sortBlock": `TransferPage_sortBlock__mb+NV`
};
export default ___CSS_LOADER_EXPORT___;
