// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_navBlock__fqltR {
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.MainPage_wrapp__WoabC {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
    text-decoration: none;
    width: 200px;
    text-align: center;
    color: white;
    padding: 15px 100px;
    border-radius: 20px;
    border: 1px solid white;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".navBlock {\r\n    position: relative;\r\n    top: 20px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 10px;\r\n}\r\n\r\n.wrapp {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\na {\r\n    text-decoration: none;\r\n    width: 200px;\r\n    text-align: center;\r\n    color: white;\r\n    padding: 15px 100px;\r\n    border-radius: 20px;\r\n    border: 1px solid white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBlock": `MainPage_navBlock__fqltR`,
	"wrapp": `MainPage_wrapp__WoabC`
};
export default ___CSS_LOADER_EXPORT___;
