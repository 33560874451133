import { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import css from './PlayerList.module.css'
import { deletePlayer, getPlayers } from "../api";

export default function PlayerListPage() {

    let [players, setPlayers] = useState([])

    useEffect(() => {
        getPlayers().then(res => {
            console.log(res)
            setPlayers(res)
        })
    }, [])

    function handleDelete(id) {
        deletePlayer(id).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Player List'}>

            <div className={css.players}>
                <div className={css.player}>
                    <h1>Discord Id</h1>
                    <h1>User handle</h1>
                    <h1>Team</h1>
                    <h1>Bank</h1>
                    <h1>Organization</h1>
                </div>
                {players.map(player => (
                    <div className={css.playerManagment}>
                        <div className={css.player}>
                            <h1>{player.discordId}</h1>
                            <h1>{player.handle}</h1>
                            <h1>{player.Team != null ? player.Team.name : 'Нету'}</h1>
                            <h1>{player.bank}</h1>
                            <h1>{player.organization}</h1>
                        </div>
                        <button onClick={() => {handleDelete(player.discordId)}}>Delete {player.handle}</button>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}