import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteDepartment, deleteTeamEvent, getDepartments, getTeamEvents} from '../api'
import { useEffect, useState } from "react";

let decrypt = {
    none: "Нету",
    srh: "Звездно-Ресурсный Холдинг",
    ebc: "Инженерно-Строительный Корпус",
    csld: "ЧВК",
    asld: "Воздушно-Космическая ЧВК",
    sei: "Научно-исследовательский Институт"
}

export default function DepartmentPage() {

    let [departments, setDepartments] = useState([])

    // function handleDelete(id) {
    //     deleteTeamEvent(id).then(() => {
    //         window.location.reload()
    //     })
    // }

    useEffect(() => {
        getDepartments().then(res => {
            setDepartments(res)
        })
    }, [])

    function handleDelete(id) {
        deleteDepartment({id}).then(res => {
            window.location.href = '/departments'
        })
    }

    return (
        <MainLayout title={'Department Page'}>
            <div className={css.header}>
                <Link to={'/department/new'}>New Department</Link>
                <h1>Departments</h1>
            </div>
            <div className={css.events}>
                {departments.sort((a,b) => (a.id - b.id)).map(department => {
                    return <div className={css.event}>
                        <h2>{department.name}</h2>
                        <h2>{decrypt[department.type]}</h2>
                        <Link to={`/department/${department.id}`}>Edit</Link>
                        <button onClick={() => {handleDelete(department.id)}}>Delete</button>
                        {/* <div className={css.buttons}>
                            <button onClick={() => {handleDelete(teamEvent.id)}}>Delete</button>
                        </div> */}
                    </div>
                })}
            </div>
        </MainLayout>
    )
}