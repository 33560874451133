import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './ExchangePage.module.css'
import { useEffect, useState } from "react";
import { deleteExchangeRole, deleteExchangeTitle, getExchangeRoles, getExchangeTitles } from "../api";


export function ExchangeTitlesPage() {

    let [items, setItems] = useState([])

    useEffect(() => {
        getExchangeTitles().then((res) => {
            setItems(res)
        })
    }, [])

    function handleDelete(id){
        deleteExchangeTitle({id}).then((res) => {
            window.location.href = '/exchange/titles'
        })
    }

    return (
        <MainLayout title={'Exchange Titles page'}>
            <div className={css.links}>
                <Link to={'/exchange'}>Debt Managment</Link>
                <Link to={'/exchange/roles'}>Role Managment</Link>
                <Link to={'/exchange/titles'}>Title Managment</Link>
                <Link to={'/exchange/items'}>Item Managment</Link>
                <Link to={'/exchange/titles/create'}>Create</Link>
            </div>

            <div className={css.body}>
                <div className={css.items}>
                    {items.map(item => {
                        return <div className={css.item}>
                            <h1>{item.DepartamentPosition.name}</h1>
                            <h1>{item.type}</h1>
                            <h1>{item.price}</h1>
                            <Link to={`/exchange/titles/${item.id}`}>Edit</Link>
                            <button onClick={() => {handleDelete(item.id)}}>Delete</button>
                        </div>
                    })}
                </div>
            </div>
        </MainLayout>
    )
}