import MainLayout from "../layouts/MainLayout";
import { Link, useParams } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteSoloEvent, finishSoloEvent, getSoloEventPlayers, getSoloEvents, updateEventPlayers} from '../api'
import { useEffect, useState } from "react";

export default function SoloEventPlayers() {

    let [players, setPlayers] = useState([])
    let {id} = useParams()

    useEffect(() => {
        getSoloEventPlayers(id).then(res => {
            setPlayers(res)
        })
    }, [])

    function changeRewardReceiving(id) {
        const newArr = players.map((r) => {
            if(r.id == id) {
                return {
                    ...r,
                    "receivingReward": !r.receivingReward
                }
            } else {
                return r
            }
        })

        setPlayers(newArr)
    }

    function saveChanges() {
        updateEventPlayers(id, {
            players: players.map(e => {
                return {
                    id: e.id,
                    receivingReward: e.receivingReward
                }
            })
        }).then(() => {
            alert("success!")
        })
    }

    return (
        <MainLayout title={'Solo Events'}>
            <div className={css.header}>
                <h1>Event Players</h1>
            </div>
            <div className={css.events}>
                {players.sort((a,b) => (a.id - b.id)).map(soloEvent => {
                    return <div className={css.event}>
                        <h1>{soloEvent.User.handle}</h1>
                        <h1>{soloEvent.PlayableRole.Role.name}</h1>
                        <h1>{soloEvent.receivingReward ? "User will receive reward" : "User won't receive reward"}</h1>
                        <button onClick={() => {changeRewardReceiving(soloEvent.id)}}>Управление наградой</button>
                        <Link to={`/solo-events/players/${id}/edit/${soloEvent.User.id}`}>Управление ролями</Link>
                    </div>
                })}
                <button id={css.save} onClick={() => {saveChanges()}}>Save changes</button>
            </div>
        </MainLayout>
    )
}