import { Link, useParams } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './ExchangePage.module.css'
import { useEffect, useState } from "react";
import { createExchangeItem, getExchangeItem, getRate, getRewards, updateExchangeItem, updateRate } from "../api";


export function ExchangeItemsEdit() {

    let [name, setName] = useState('')
    let [price, setPrice] = useState(0)
    let [description, setDescription] = useState('')
    let [image, setImage] = useState('')
    let [type, setType] = useState('')
    let {id} = useParams()

    useEffect(() => {
        getExchangeItem(id).then((res) => {
            setPrice(res.price)
            setName(res.name)
            setDescription(res.description)
            setImage(res.image)
            setType(res.type)
        })
    }, [])
    function handleSave() {
        updateExchangeItem({
            price,
            name,
            description,
            image,
            id,
            type
        }).then(() => {
            window.location.href = '/exchange/items'
        })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        const reader = new FileReader();

        reader.onload = () => {
            const base64Result = reader.result;
            setImage(base64Result)
        };

        reader.readAsDataURL(file);
    };

    return (
        <MainLayout title={'Exchange Item edit'}>
            <div className={css.links}>
                <Link to={'/exchange'}>Debt Managment</Link>
                <Link to={'/exchange/roles'}>Role Managment</Link>
                <Link to={'/exchange/titles'}>Title Managment</Link>
                <Link to={'/exchange/items'}>Item Managment</Link>
            </div>
            <div className={css.body}>
                <div className={css.inputs}>

                <div className={css.inputBox}>
                    <h2>Name</h2>
                    <input value={name} onChange={(e) => {setName(e.target.value)}} type="text" />
                </div>
                <div className={css.inputBox}>
                    <h2>Description</h2>
                    <textarea value={description} onChange={(e) => {setDescription(e.target.value)}} type="text" />
                </div>
                <div className={css.inputBox}>
                    <h2>Price</h2>
                    <input value={price} onChange={(e) => {setPrice(e.target.value)}} type="text" />
                </div>

                <div className={css.inputBox}>
                        <h2>Type</h2>
                        <select value={type} onChange={(e) => {setType(e.target.value)}}>
                            <option value={'none'}>None</option>
                            <option value="solo">Regular</option>
                            <option value="leader">VIP</option>
                        </select>
                </div>

                <div className={css.inputBox}>
                    <h2>image</h2>
                    <input value={image} onChange={(e) => {handleFileChange(e.target.value)}} type="file" />
                </div>

                </div>
                <button onClick={() => {handleSave()}}>Save</button>
            </div>
        </MainLayout>
    )
}