import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './PlayerList.module.css'
import {deleteTeam, getTeams} from '../api'
import { useEffect, useState } from "react";

export default function TeamsPage() {

    let [teams, setTeams] = useState([])

    useEffect(() => {
        getTeams().then(res => {
            console.log(res)
            setTeams(res)
        })
    }, [])

    function handleDelete(id) {
        deleteTeam(id).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Teams'}>
            <div className={css.players}>
                <div className={css.player}>
                    <h1>ID</h1>
                    <h1>Name</h1>
                    <h1>Team Event Name</h1>
                    <h1>Players</h1>
                </div>
                {teams.map(team => (
                    <div className={css.playerManagment}>
                        <div className={css.player}>
                            <h1>{team.id}</h1>
                            <h1>{team.name}</h1>
                            <h1>{team.TeamEvent?.name ?? 'Нету ивента'}</h1>
                            <h1>{team.players}</h1>
                        </div>
                        <button onClick={() => {handleDelete(team.id)}}>Delete</button>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}