import './App.css';
import RenderRoutes from './router'
import {BrowserRouter, Route, RouterProvider, Routes} from 'react-router-dom'

function App() {
  return (
    <RouterProvider router={RenderRoutes}></RouterProvider>
  );
}

export default App;
