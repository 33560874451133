import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './ExchangePage.module.css'
import { useEffect, useState } from "react";
import { createExchangeRole, getRate, getRewards, getRoles, updateRate } from "../api";


export function ExchangeRoleCreate() {

    let [roles, setRoles] = useState([])
    let [price, setPrice] = useState(0)
    let [roleId, setRoleId] = useState('none')

    useEffect(() => {
        getRoles().then(res => {
            setRoles(res)
        })
    }, [])
    

    function handleSave() {
        if(roleId == 'none') return alert('Choose Role')
        createExchangeRole({
            price,
            RoleId: roleId
        }).then(() => {
            window.location.href = '/exchange/roles'
        })
    }

    return (
        <MainLayout title={'Exchange Role create'}>
            <div className={css.links}>
                <Link to={'/exchange'}>Debt Managment</Link>
                <Link to={'/exchange/roles'}>Role Managment</Link>
                <Link to={'/exchange/titles'}>Title Managment</Link>
                <Link to={'/exchange/items'}>Item Managment</Link>
            </div>

            <div className={css.body}>
                <div className={css.inputs}>
                    <div className={css.inputBox}>
                        <h2>Item</h2>
                        <select onChange={(e) => {setRoleId(e.target.value)}}>
                            <option value={'none'}>None</option>
                            {roles.map(role => {
                                return <option value={role.id}>{role.name}</option>
                            })}
                        </select>
                    </div>
                    <div className={css.inputBox}>
                        <h2>Price</h2>
                        <input value={price} onChange={(e) => {setPrice(e.target.value)}} type="text" />
                    </div>
                </div>
                <button onClick={() => {handleSave()}}>Save</button>
            </div>
        </MainLayout>
    )
}