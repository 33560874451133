import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteTeamEvent, getTeamEventApplications, getTeamEvents, updateTeamEventApplications} from '../api'
import { useEffect, useState } from "react";

export default function TeamEventApplications() {

    let [applications, setApplications] = useState([])

    useEffect(() => {
        getTeamEventApplications().then(res => {
            setApplications(res)
        })
    }, [])

    async function handleApprove(id) {
        updateTeamEventApplications({
            id,
            status: 'approved'
        }).then(() => {
            window.location.reload()
        })
    }

    async function handleReject(id) {
        updateTeamEventApplications({
            id,
            status: 'rejected'
        }).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Team Event Applications'}>
            <div className={css.header}>
                <h1>Applications</h1>
            </div>
            <div className={css.events}>
                {applications.sort((a,b) => (a.id - b.id)).map(application => {
                    return <div className={css.event}>
                        <h2>Application ID: {application.id}</h2>
                        <h2>Team Name: {application.Team.name}</h2>
                        <h2>Team players: {application?.players}</h2>
                        <h2>Team Owner ID: {application.Team.ownerId}</h2>
                        <h2>Team Event Name: {application.TeamEvent.name}</h2>
                        <div className={css.buttons}>
                            <button id={css.approve} onClick={() => {handleApprove(application.id)}}>Approve</button>
                            <button onClick={() => {handleReject(application.id)}}>Reject</button>
                        </div>
                    </div>
                })}
            </div>
        </MainLayout>
    )
}