import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './PlayerList.module.css'
import {deleteRole, getRoles} from '../api'
import { useEffect, useState } from "react";

let decrypt = {
    none: "Нету",
    srh: "Звездно-Ресурсный Холдинг",
    ebc: "Инженерно-Строительный Корпус",
    csld: "ЧВК",
    asld: "Воздушно-Космическая ЧВК",
    sei: "Научно-исследовательский Институт"
}

export default function RolesPage() {

    let [roles, setRoles] = useState([])

    useEffect(() => {
        getRoles().then(res => {
            console.log(res)
            setRoles(res)
        })
    }, [])

    function handleDelete(id) {
        deleteRole({id}).then(() => {
            window.location.href = '/roles'
        })
    }

    return (
        <MainLayout title={'Roles'}>
            <div className={css.header}>
                <Link to={'/roles/create'}>New</Link>
            </div>
            <div className={css.players}>
                <div className={css.player}>
                    <h1>ID</h1>
                    <h1>Name</h1>
                    <h1>Category</h1>
                </div>
                {roles.map(role => (
                    <div className={css.player}>
                        <h1>{role.id}</h1>
                        <h1>{role.name}</h1>
                        <h1>{decrypt[role.category]}</h1>
                        <Link to={`/roles/${role.id}`}>Edit</Link>
                        <button onClick={() => {handleDelete(role.id)}}>Delete</button>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}