// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerList_players__rgayu {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.PlayerList_player__4tM8y {
    display: flex;
    padding: 5px;
    border: 1px solid white;
    flex-direction: row;
    justify-content: space-between;
}

.PlayerList_player__4tM8y h1 {
    font-size: 20px;
    width: 15%;
    height: 50px;
    overflow: auto;
}

.PlayerList_header__U\\+nHR {
    height: 50px;
}

.PlayerList_playerManagment__jFOnW {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.PlayerList_playerManagment__jFOnW button {
    background-color: rgb(163, 47, 47);
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
}

.PlayerList_playerManagment__jFOnW a {
    background-color: rgb(163, 47, 47);
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
}

#PlayerList_edit__U-vr6 {

    background-color: rgb(163, 86, 47);
}

#PlayerList_parseShip__nKZFR {
    background-color: rgb(26, 115, 35);
    border: none;
    color: white;
    padding: 15px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PlayerList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;;IAEI,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".players {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n}\r\n.player {\r\n    display: flex;\r\n    padding: 5px;\r\n    border: 1px solid white;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}\r\n\r\n.player h1 {\r\n    font-size: 20px;\r\n    width: 15%;\r\n    height: 50px;\r\n    overflow: auto;\r\n}\r\n\r\n.header {\r\n    height: 50px;\r\n}\r\n\r\n.playerManagment {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n}\r\n\r\n.playerManagment button {\r\n    background-color: rgb(163, 47, 47);\r\n    border: none;\r\n    color: white;\r\n    padding: 15px;\r\n    cursor: pointer;\r\n}\r\n\r\n.playerManagment a {\r\n    background-color: rgb(163, 47, 47);\r\n    border: none;\r\n    color: white;\r\n    padding: 15px;\r\n    cursor: pointer;\r\n}\r\n\r\n#edit {\r\n\r\n    background-color: rgb(163, 86, 47);\r\n}\r\n\r\n#parseShip {\r\n    background-color: rgb(26, 115, 35);\r\n    border: none;\r\n    color: white;\r\n    padding: 15px 20px;\r\n    margin-bottom: 20px;\r\n    cursor: pointer;\r\n    margin-right: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"players": `PlayerList_players__rgayu`,
	"player": `PlayerList_player__4tM8y`,
	"header": `PlayerList_header__U+nHR`,
	"playerManagment": `PlayerList_playerManagment__jFOnW`,
	"edit": `PlayerList_edit__U-vr6`,
	"parseShip": `PlayerList_parseShip__nKZFR`
};
export default ___CSS_LOADER_EXPORT___;
