import { createBrowserRouter } from "react-router-dom";
import {MainPage} from "./pages/MainPage";
import SettingsPage from "./pages/SettingsPage";
import DebtPage from "./pages/DebtPage";
import TransferPage from "./pages/TransferPage";
import SoloEvents from "./pages/SoloEvents";
import SoloNew from "./pages/SoloNew";
import SoloEdit from "./pages/SoloEdit";
import PlayerListPage from "./pages/PlayerListPage";
import LoggingPage from "./pages/LoginPage";
import AuthorizedPage from "./pages/AuthorizedPage";
import BankPage from "./pages/BankPage";
import TeamEvents from "./pages/TeamEvents";
import TeamEventsNew from "./pages/TeamEventsNew";
import TeamEventsEdit from "./pages/TeamEventsEdit";
import TeamsPage from "./pages/TeamsPage";
import RolesPage from "./pages/RolesPage";
import RewardsPage from "./pages/RewardsPage";
import RoleNew from "./pages/RoleNew";
import RewardNew from "./pages/RewardNew";
import RewardEdit from "./pages/RewardEdit";
import { ExchangePage } from "./pages/ExchangePage";
import { ExchangeItemsPage } from "./pages/ExchangeItems";
import { ExchangeRolesPage } from "./pages/ExchangeRoles";
import { ExchangeItemsCreate } from "./pages/ExchangeItemsCreate";
import { ExchangeRoleCreate } from "./pages/ExchangeRoleCreate";
import { RecruitmentsPage } from "./pages/RecruitmentsPage";
import { RecruitmentPage } from "./pages/RecruitmentPage";
import ShipPage from "./pages/Ships";
import TeamEventApplications from "./pages/TeamEventApplications";
import DepartmentPage from "./pages/Departments";
import DepartmentNew from "./pages/DepartmentNew";
import { ExchangeRoleEdit } from "./pages/ExchangeRoleEdit";
import { ExchangeItemsEdit } from "./pages/ExchangeItemEdit";
import DepartmentEdit from "./pages/DepartmentEdit";
import TeamEventEnd from "./pages/TeamEventEnd";
import AwaitingExchanges from "./pages/AwaitingExchanges";
import { ExchangeTitleEdit } from "./pages/ExchangeTitleEdit";
import { ExchangeTitleCreate } from "./pages/ExchangeTitleCreate";
import { ExchangeTitlesPage } from "./pages/ExchangeTitles";
import SoloEventPlayers from "./pages/SoloEventPlayers";
import SoloEventPlayer from "./pages/SoloEventPlayer";
import TeamEventPlayers from "./pages/TeamEventPlayers";

function checkAuth(Component) {
  if(localStorage.getItem('token') == undefined) return <LoggingPage></LoggingPage>
  return <Component></Component>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: checkAuth(MainPage),
  },
  {
    path: '/settings',
    element: checkAuth(SettingsPage)
  },
  {
    path: '/bank',
    element: checkAuth(BankPage)
  }, 
  {
    path: "/bank/debt",
    element: checkAuth(DebtPage)
  },
  {
    path: "/bank/transfers",
    element: checkAuth(TransferPage)
  },
  {
    path: "/solo-events",
    element: checkAuth(SoloEvents)
  },
  {
    path: '/solo-events/new',
    element: checkAuth(SoloNew)
  },
  {
    path: '/solo-events/edit/:id',
    element: checkAuth(SoloEdit)
  },
  {
    path: '/solo-events/players/:id',
    element: checkAuth(SoloEventPlayers)
  },
  {
    path: '/solo-events/players/:id/edit/:userId',
    element: checkAuth(SoloEventPlayer)
  },
  {
    path: '/players',
    element: checkAuth(PlayerListPage)
  }, {
    path: "/authorized",
    element: <AuthorizedPage></AuthorizedPage>
  },
  {
    path: "/team-events",
    element: checkAuth(TeamEvents)
  },
  {
    path: '/team-events/new',
    element: checkAuth(TeamEventsNew)
  },
  {
    path: '/team-events/edit/:id',
    element: checkAuth(TeamEventsEdit)
  },
  {
    path: '/team-events/players/:id',
    element: checkAuth(TeamEventPlayers)
  },
  {
    path: '/team-events/finish/:id',
    element: checkAuth(TeamEventEnd)
  },
  {
    path: '/teams',
    element: checkAuth(TeamsPage)
  }, 
  {
    path: '/roles',
    element: checkAuth(RolesPage)
  },
  {
    path: '/roles/create',
    element: checkAuth(RoleNew)
  },
  {
    path: '/roles/:id',
    element: checkAuth(RoleNew)
  },
  {
    path: '/rewards',
    element: checkAuth(RewardsPage)
  },
  {
    path: '/rewards/create',
    element: checkAuth(RewardNew)
  },
  {
    path: '/rewards/edit/:id',
    element: checkAuth(RewardEdit)
  },
  {
    path: '/exchange',
    element: checkAuth(ExchangePage)
  },
  {
    path: '/exchange/roles',
    element: checkAuth(ExchangeRolesPage)
  },
  {
    path: '/exchange/items',
    element: checkAuth(ExchangeItemsPage)
  },
  {
    path: '/exchange/titles',
    element: checkAuth(ExchangeTitlesPage)
  },
  {
    path: '/exchange/items/create',
    element: checkAuth(ExchangeItemsCreate)
  },
  {
    path: '/exchange/roles/create',
    element: checkAuth(ExchangeRoleCreate)
  },
  {
    path: '/exchange/roles/:id',
    element: checkAuth(ExchangeRoleEdit)
  },
  {
    path: `/exchange/items/:id`,
    element: checkAuth(ExchangeItemsEdit)
  },
  {
    path: '/exchange/titles/create',
    element: checkAuth(ExchangeTitleCreate)
  },
  {
    path: '/exchange/titles/:id',
    element: checkAuth(ExchangeTitleEdit)
  },
  {
    path: "/recruitments",
    element: checkAuth(RecruitmentsPage)
  },
  {
    path: "/recruitment/:id",
    element: checkAuth(RecruitmentPage)
  },
  {
    path: "/ships",
    element: checkAuth(ShipPage)
  },
  {
    path: "/team-event-applications",
    element: checkAuth(TeamEventApplications)
  },
  {
    path: "/departments",
    element: checkAuth(DepartmentPage)
  },
  {
    path: `/department/:id`,
    element: checkAuth(DepartmentEdit)
  },
  {
    path: "/department/new",
    element: checkAuth(DepartmentNew)
  },
  {
    path: "/awaiting-exchanges",
    element: checkAuth(AwaitingExchanges)
  }
]);

export default router;