import { Link } from 'react-router-dom'
import css from './MainLayout.module.css'

export default function MainLayout({children, title}) {
    return (
        <div className={css.bodyApp}>
            <div className={css.header}>
                <Link to={'/'}>Main Menu</Link>
                <h1>{title}</h1>
            </div>
            <div className={css.container}>
                {children}
            </div>
        </div>
    )
}