// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DebtPage_debtList__R-w94 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.DebtPage_debtRequest__sEHLH {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin: 0;
}

.DebtPage_debtRequest__sEHLH .DebtPage_buttons__mHfu5 {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.DebtPage_debtRequest__sEHLH button {
    padding: 10px 15px;
    cursor: pointer;
}

#DebtPage_reject__4\\+m1Q {
    border: none;
    background: red;
    color: white;
    font-weight: 900;
}

#DebtPage_approve__\\+BEo2 {
    border: none;
    background: green;
    color: white;
    font-weight: 900;
}

.DebtPage_sortBlock__e6\\+Db button {
    padding: 10px;
    border: 1px solid white;
    background: none;
    color: white;
    width: 150px;
    cursor: pointer;
}

.DebtPage_sortBlock__e6\\+Db {
    display: flex;
    flex-direction: row;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DebtPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".debtList {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    width: 100%;\r\n}\r\n\r\n.debtRequest {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    width: 100%;\r\n    justify-content: space-around;\r\n    margin: 0;\r\n}\r\n\r\n.debtRequest .buttons {\r\n    width: 200px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.debtRequest button {\r\n    padding: 10px 15px;\r\n    cursor: pointer;\r\n}\r\n\r\n#reject {\r\n    border: none;\r\n    background: red;\r\n    color: white;\r\n    font-weight: 900;\r\n}\r\n\r\n#approve {\r\n    border: none;\r\n    background: green;\r\n    color: white;\r\n    font-weight: 900;\r\n}\r\n\r\n.sortBlock button {\r\n    padding: 10px;\r\n    border: 1px solid white;\r\n    background: none;\r\n    color: white;\r\n    width: 150px;\r\n    cursor: pointer;\r\n}\r\n\r\n.sortBlock {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debtList": `DebtPage_debtList__R-w94`,
	"debtRequest": `DebtPage_debtRequest__sEHLH`,
	"buttons": `DebtPage_buttons__mHfu5`,
	"reject": `DebtPage_reject__4+m1Q`,
	"approve": `DebtPage_approve__+BEo2`,
	"sortBlock": `DebtPage_sortBlock__e6+Db`
};
export default ___CSS_LOADER_EXPORT___;
