import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import css from './DebtPage.module.css'
import { useEffect, useState } from "react";
import { getDebtRequests, getDebtors, removeDebtor, updateDebtRequest } from "../api";

export default function DebtPage() {

    let [debts, setDebts] = useState([])
    let [debtors, setDebtors] = useState([])
    let [sortState, setSortState] = useState('debts')

    useEffect(() => {
        getDebtRequests().then(res => {
            setDebts(res)
        })

        getDebtors().then(res => {
            setDebtors(res)
        })
    }, [])

    function handleApprove(id, approved) {
        updateDebtRequest({
            id,
            approved
        })

        window.location.reload()
    }

    function handleRemove(id) {
        removeDebtor(id).then(() => {
            window.location.reload()
            // alert('success!')
        })

    }

    return (
        <MainLayout title={'Debt Requests'}>
            <div className={css.sortBlock}>
                <button onClick={() => {setSortState('debts')}}>Debt Requests</button>
                <button onClick={() => {setSortState('debtors')}}>Debtors</button>
            </div>



        
            <div className={css.debtList}>
                {sortState == 'debts' ? debts.map(debt => {
                    if(debt.status != 'checked') {
                        return (<div className={css.debtRequest}>
                            <p>{debt.handle}</p>
                            <p>{debt.discordId}</p>
                            <p>{debt.amount}</p>
                            <p>{debt.status}</p>
                            <p>{debt.time}</p>
                            <p>{debt.note}</p>
                            <div className={css.buttons}>
                                <button disabled={debt.status == 'checked'} onClick={() => {handleApprove(debt.id, false)}} id={css.reject}>Reject</button>
                                <button disabled={debt.status == 'checked'} onClick={() => {handleApprove(debt.id, true)}} id={css.approve}>Approve</button>
                            </div>
                        </div>)
                    }
                }) : debtors.map(debtor => {
                    return (<div className={css.debtRequest}>
                        <p>Debtor: {debtor.handle}</p>
                        <p>Amount: {debtor.amount}</p>
                        <button onClick={() => {handleRemove(debtor.id)}} id={css.reject}>Remove</button>
                    </div>)
                })}
            </div>
        </MainLayout>
    )
}