import { Link } from "react-router-dom";
import css from './MainPage.module.css'

export function MainPage() {

    return (
        <div className={css.wrapp}>
            <div className={css.navBlock}>
                <Link to={'/settings'}>Settings</Link>
                <Link to={'/bank'}>Bank</Link>
                <Link to={'/solo-events'}>Solo events</Link>
                <Link to={'/team-events'}>Team events</Link>
                <Link to={'/team-event-applications'}>Team Event Applications</Link>
                <Link to={'/players'}>Player List</Link>
                <Link to={'/teams'}>Teams</Link>
                <Link to={'/roles'}>Roles</Link>
                <Link to={'/rewards'}>Rewards</Link>
                <Link to={'/exchange'}>Exchange</Link>
                <Link to={'/recruitments'}>Recruitments</Link>
                <Link to={'/ships'}>Ships</Link>
                <Link to={'/departments'}>Deparments</Link>
                <Link to={'/awaiting-exchanges'}>Awaiting Exchanges</Link>
            </div>
        </div>
    )
}